@font-face {
  font-family: "RoundsBlack";
  src: local("RoundsBlack"),
  url(../public/fonts/Rounds-Black.woff2) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'), 
  url('../public/fonts/Robotothin.woff2') format('woff2'), 
  url('../public/fonts/Robotothin.woff') format('woff'), 
  url('../public/fonts/Robotothin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto bold'), local('Roboto-bold'), 
  url('../public/fonts/Robotobold.woff2') format('woff2'), 
  url('../public/fonts/Robotobold.woff') format('woff'), 
  url('../public/fonts/Robotobold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), 
  url('../public/fonts/Roboto.woff2') format('woff2'), 
  url('../public/fonts/Roboto.woff') format('woff'), 
  url('../public/fonts/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html, body { 
  position: relative;
  max-width: 100%; 
  overflow-x: hidden; 
}

body {
  /* background: url("../public/images/stars_bkg.png"); */
  background: #000;
  font-family: 'Roboto';
}

.__hidden {
  visibility: hidden;
}

@keyframes roundingBorder {
  from {
    background: conic-gradient(rgba(0,0,0,0.1) 0deg, rgba(0,0,0,0.1) 90deg, #313149 90deg, #313149 360deg);
  }
  25% {
    background: conic-gradient(rgba(0,0,0,0.1) 0deg, rgba(0,0,0,0.1) 135deg, #313149 135deg, #313149 360deg);
  }
  50% {
    background: conic-gradient(rgba(0,0,0,0.1) 0deg, rgba(0,0,0,0.1) 225deg, #313149 225deg, #313149 360deg);
  }
  75% {
    background: conic-gradient(rgba(0,0,0,0.1) 0deg, rgba(0,0,0,0.1) 270deg, #313149 270deg, #313149 360deg);
  }
  to {
    background: conic-gradient(rgba(0,0,0,0.1) 0deg, rgba(0,0,0,0.1) 359deg, #313149 359deg, #313149 360deg);
  }
}

@keyframes roundingBorder_err {
  from {
    background: conic-gradient(rgba(234, 10, 10, 0.201) 0deg, rgba(234, 12, 12, 0.1) 90deg, #d41d47 90deg, #db5567 360deg);
  }
  25% {
    background: conic-gradient(rgba(234, 10, 10, 0.201) 0deg, rgba(234, 12, 12, 0.1)  135deg, #d41d47  135deg, #db5567 360deg);
  }
  50% {
    background: conic-gradient(rgba(234, 10, 10, 0.201) 0deg, rgba(234, 12, 12, 0.1)  225deg, #d41d47  225deg, #db5567 360deg);
  }
  75% {
    background: conic-gradient(rgba(234, 10, 10, 0.201) 0deg, rgba(234, 12, 12, 0.1)  270deg, #d41d47  270deg, #db5567 360deg);
  }
  to {
    background: conic-gradient(rgba(234, 10, 10, 0.201) 0deg, rgba(234, 12, 12, 0.1)  359deg, #d41d47 359deg, #db5567 360deg);
  }
}

@keyframes roundingBorder_ok {
  from {
    background: conic-gradient(#176703 0deg, #46a00b 90deg, #99e473 90deg, #8ad955 360deg);
  }
  25% {
    background: conic-gradient(#176703 0deg, #46a00b  135deg, #99e473  135deg, #8ad955 360deg);
  }
  50% {
    background: conic-gradient(#176703 0deg, #46a00b  225deg, #99e473  225deg, #8ad955 360deg);
  }
  75% {
    background: conic-gradient(#176703 0deg, #46a00b  270deg, #99e473  270deg, #8ad955 360deg);
  }
  to {
    background: conic-gradient(#176703 0deg, #46a00b  359deg, #99e473 359deg, #8ad955 360deg);
  }
}

@keyframes roundingReverse {
  from {
    transform: rotate(-359deg);
  }
  25% {
    transform: rotate(-270deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  75% {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(-1deg);
  }
}

@keyframes rounding {
  from {
    transform: rotate(-359deg);
  }
  25% {
    transform: rotate(-270deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  75% {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(-1deg);
  }
}


.office--page {
  margin: 0 64px;
}

.Progress--section {
   width: 100%;
   height: 300px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 50px;
   color: #FFF;
}

.rotate--anim {
  animation: rounding;
  animation-duration: 11s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.rotate--anim--reverse {
  animation: roundingReverse;
  animation-duration: 11s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input::-webkit-input-placeholder{
  text-align:right;
  font-size: 20px;
  font-weight: 300;
  margin-top: 4px;
}

video::-webkit-media-controls {
  display:none !important;
}

.body--unfixed {
  min-height: 160vh;
}

.body--fixed {
  height: 100%;
  overflow: hidden;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  padding-top: 30%;
}

.dex--header {
  height: 60px;
  width: 100%;
  position:fixed;
  display: flex;
  justify-content: space-between;
  background: #000;
  z-index: 220;
}

.logo--section {
  width: 221px;
  margin: 10px;
}

.menu--section {
  display: flex;
  color: #FFF;
  margin: 10px;
}

.menu--section .menu--item--first {
  border-left: 2px solid #FFF;
}

.menu--section .menu--item {
   margin: 10px 0px;
   padding: 0px 30px;
   border-right: 2px solid #FFF;
}

.wallet--section {
  margin: 10px;
}

.inActive {
  color: #D9D9D94D;
  background: none;
  text-shadow: none;
}


.menu--item a {
  color: #FFF;
  text-decoration: none;
}

.active a {
  color: #ACF800;
}


.main--submenu {
  position: absolute;
  width: 200px;
  margin-left: -16px;
  background: transparent;
  border-radius: 6px;
  display: none;
}

.main--submenu a {
  width: 100%;
  margin-left: 16px;
}

.submenu--item {
  padding-top: 16px;
  width: 100%;
  min-height: 32px;
  cursor: pointer;
  background: #352F44;
}

.submenu--item--first {
  margin-top: 8px;
  border-radius: 6px 6px 0 0;
}

.submenu--item--last {
  border-radius: 0 0 6px 6px;
}

.submenu--item:hover,
.submenu--item:focus,
.submenu--item:hover a,
.submenu--item:focus a {
  background: #ACF800;
  color: #000;
}

.menu--item:hover .main--submenu,
.menu--item:focus .main--submenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.starmap--btn a {
  border: 1px solid #00F0FF;
  border-radius: 4px;
  padding: 4px 12px;
  color: #00F0FF;
  text-transform: uppercase;
}

.starmap--btn a:hover,
.starmap--btn a:focus {
  background: #00F0FF;
  border-radius: 4px;
  padding: 4px 12px;
  color: #000;
}

.green--btn a {
  background: #ACF800;
  border-radius: 4px;
  padding: 4px 12px;
  color: #000;
  text-transform: uppercase;
}

.green--btn.inActive a {
  border: 1px solid #ACF800;
  background: transparent;
  border-radius: 4px;
  padding: 4px 12px;
  color: #ACF800;
  text-transform: uppercase;
}

.green--btn.inActive a:hover,
.green--btn.inActive a:focus {
  background: #ACF800;
  border-radius: 4px;
  padding: 4px 12px;
  color: #000;
  text-transform: uppercase;
}

.connectWallet--btn {
  width: 160px;
  border-radius: 40px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  padding-top: 7px;
  background: #ACF800;
  color: #1E1E1E;
  cursor: pointer;
  user-select: none;
}

.wallet--connected {
  width: 160px;
  border-radius: 40px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  padding-top: 7px;
  color: #FFF;
  background: #352F44;
}

.user--menu {
  height: 120px;
  margin-top: -60px;
  padding-right: 30px;
  background: #352F44;
  color: #FFF;
  border-radius: 40px;
  display: none;
}

.user--menu p {
  padding-top: 72px;
  padding-left: 28px;
  cursor: pointer;
}

.wallet--connected:hover .user--menu,
.wallet--connected:focus .user--menu,
.wallet--connected:active .user--menu {
  display: block;
}

.presale--body {
  display: flex;
  width: 100%;
  max-width: 1292px;
  margin-left: auto;
  margin-right: auto;
}

.star--section {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0px;
}

.star--image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.star--section img,
.star--section video {
  width: 100%;
  max-width: 620px;
  pointer-events: none;
}

.token--selector {
  width: 100%;
  height: 32px;
  margin-top: 52px;
  display: flex;
  justify-content: center;
}

.currency--selector {
  width: 100%;
  height: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.selected--token {
  display: flex;
  margin: 0 10px;
}

.max--value--btn {
  margin-top: 18px;
  color: #4DA1A3;
  font-weight: 700;
  font-size: 12px;
}

.selected--token img {
  margin-top: 10px;
}

.selected--token p {
  margin-left: 10px;
  font-weight: 300;
  font-size: 16px;
  color: #F1F6F9;
}

/* VRP, VDAO - checkbox */

.token--selector .token {
  font-size: 18px;
  font-family: "RoundsBlack";
  text-transform: uppercase;
  margin: 0 10px;
}

.token--vrp {
  color: #96E6A1;
  background: transparent;
  text-shadow: 0 0 20px #D4FC79;
}

.token--vdao {
  background: linear-gradient(2.11deg, #AF15FF 4.13%, #3D217F 147.4%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 20px #3E12F0;
}

.switch--token {
  display: block;
  width: 60px;
  height: 34px;
  margin-top: -6px;
  cursor: pointer;
}

.switch--token input {
  display:none;
}

.checker {
  cursor: pointer;
  display: block;
  background: linear-gradient(to left, #1A192E, #352F44);
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 5px 2px #00000026 inset;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 40px;
}

.checker:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  margin-top: 2px;
  background: linear-gradient(97.85deg, #D4FC79 3.64%, #96E6A1 94.38%);
  box-shadow: 0px 0px 30px #AAF765;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 40px;
}

input:checked + .checker {
  background: linear-gradient(to right, #1A192E, #352F44);;
}

input:checked + .checker:before {
  background: linear-gradient(180deg, #667EEA 0%, #764BA2 100%);
  transform: translateX(30px);
}

/* VRP, VDAO - checkbox end */


.sale--timer {
  font-family: "RoundsBlack";
  font-weight: 700;
  font-size: 32px;
  color: #ACF801;
  text-transform: uppercase;
  margin-top: -16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal--message {
  width: 100%;
  margin: 20px 0;
  text-align: center;
  text-transform: uppercase;
}


.rise--anim {
  position: absolute;
  z-index: 213;
}

.rise--anim img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 216;
}

/* Right column */


.buy--section {
  margin-top: 100px;
  width: 38%;
  margin-bottom: 200px;
}

.value--subsection {
  width: 100%;
  max-width: 486px;
  border: 1px solid #2E2E2E;
  border-radius: 20px;
  display: flex;
}

.buy--column--left {
  width: 60%;
  border-right: 1px solid #2E2E2E;
}

.buy--column--right {
  width: 40%;
  /* border-left: 1px solid #2E2E2E; */
}

.buy--column--heading,
.stage--heading {
  width: 100%;
  font-family: "RoundsBlack";
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  color: #FFF;
  padding: 12px 0;
  border-bottom: 1px solid #2E2E2E;
}

.buy--section input {
  width: calc(100% - 14px);
  height: 40px;
  margin-left: 3px;
  text-align: right;
  color: #1E1E1E;
  font-weight: 300;
  font-size: 20px;
  text-align: right;
}


.amount--calculator {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 3px;
}

.number--key {
  width: calc(33% - 4px);
  border: 1px solid #2E2E2E;
  cursor: pointer;
  text-align: center;
  color: #F1F6F9;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  height: 29px;
  margin-top: 4px;
  padding-top: 12px;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;  
}

.backspace--key {
  width: calc(67% - 4px);
}

@media (pointer: fine) {
  .number--key:hover {
    color: #000;
    background: #F1F6F9;
  }
}

@media (pointer: coarse) { 

  .number--key {
    transition-property: none;
  }

  .number--key:focus,
  .number--key:active {
    color: #000;
    background: #F1F6F9;
  }

  .amount--input input {
    background: #F1F6F9;
  }
}


.stage--stats {
  margin: 8px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 300;
  color: #F1F6F9;
}
.stage--stats p {
  line-height: 0.4;
}

.stage--stats b {
  font-weight: 500;
}

.buy--column--cell {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  color: #F1F6F9;
  border-top: 1px solid #2E2E2E;
  padding-top: 12px;
}

.value--subtitle {
   margin-top: 15px;
   font-size: 13px;
   text-transform: uppercase;
   text-align: center;
   width: 100%;
}

.value--subtitle.red {
  font-size: 10px;
  color: #FF0000;
  font-weight: 500;
}

.value--subtitle.green {
  font-size: 10px;
  color: #00A10D;
  font-weight: 500;
}

.row--1 {
  height: 38px;
}

.row--2 {
  height: 79.5px;
}

.stage--heading {
  height: 38px;
}

.no--border {
  border: none;
}

.confirm--button {
  font-family: "RoundsBlack";
  margin-top: 15px;
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  height: 58px;
  cursor: pointer;
  padding-top: 20px;
  color: #1E1E1E;
  border-radius: 20px;
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  user-select: none;
}

.confirm--button.vrp {
  background: #ACF801;
}

.confirm--button.vdao {
  background: linear-gradient(2.11deg, #AF15FF 4.13%, #3D217F 147.4%);
  color: #FFF;
}

.opened--arrow {
  position: absolute;
   width: 20px;
   height: 20px;
   margin-top: 48px;
   z-index: -1;
   transform: matrix(-0.7,1,0.8,1,0,0);
   border-radius: 2px;
   display: none;
}

.opened--arrow.active {
  display: block;
}

.opened--arrow.active.vrp {
  background: #ACF801;
}

.opened--arrow.active.vdao {
  background: #AF15FF
}


.timer--vdao {
  color: #9418E1;
  ;
}

.modal--overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #2D2D2D;
  opacity: 0.7;
  z-index: 225;
  display: none;
}

.modal--overlay.active {
  display: block;
}


.modal--window {
    position: fixed;
    width: 336px;
    left: calc(50% - 168px);
    top: calc(50% - 151px);
    z-index: 257;
    background: #FFF;
    border-radius: 40px;
    padding: 40px;
}

.select--row {
  display: flex;
  margin-top: 20px;
  border-radius: 40px;
}

.select--name {
  margin-left: 10px;
  margin-top: 20px;
}

.select--row:hover,
.select--row:focus {
  background: #DBD8E3;
  cursor: pointer;
}

.invest--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  padding: 4px;
  margin-left: -6px;
  max-width: 496px;
  /* transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: linear; */
}

.invest--opened {
  margin: 0px;
  max-width: none;
  max-width: 486px;
  margin-top: 2px;
  /* margin-left: -1px; */
}

.invest--section.opened {
  border: 1px solid #2E2E2E;
  border-radius: 20px;
  padding: 4px;
  margin-top: 30px;
  margin-left: -6px;
  max-width: 496px;
}

.invest--count--section {
  margin-top: 40px;
  border-radius: 20px;
  background:#352F44;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.invest--num {
  color: #FFF;
  text-transform: uppercase;
}

.buy--section input.order--input {
  text-align: left;
  border: none;
  background: transparent;
  color: #FFF;
}

.invest--description {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 10px;
}


.agree--dot {
  color: #FFF;
  font-size: 10px;
}


.invest--description p {
  width: 80%;
  text-align: center;
}

.agree--dot {
  display: flex;
  margin-top: 36px;
  margin-left: 18px;
}

.agree--label {
  width: 30px;
  height: 30px;
  background: #352F44;
  border-radius: 10px;
}

.agree--checker {
  display: block;
  width: 28px;
  height: 28px;
}

.agree--checker.agreed {
 background-image: url('../public/images/icons/agree_check.svg');
 background-repeat: no-repeat;
 margin-top: 6px;
 margin-left: 4px;
}

.rise--anim, 
.rise--anim img {
  user-select: none;
}

.agree--label input {
  display: none;
}

.agree--dot p {
  margin-left: 6px;
}

.invest--description a,
.agree--dot a {
  color:#AAF765;
  text-decoration: none;
}


.buy--confirm--section {
  display: flex;
  justify-content: space-between;
}

.final--button {
  width: 45%;
}

.btn--disabled {
  opacity: 0.9;
  background: #CFCFCF !important;
  box-shadow: inset 0px 0px 80px #000000;
  color: rgba(30, 30, 30, 0.3) !important;
  pointer-events: none;
}

.mobile--menu {
  display: none;
}

.modal--env {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 790;
}

.success--modal {
  background: no-repeat url('../public/images/success_bkg.svg');
  background-size: 100%;
}

.modal--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.modal--heading,
.modal--description {
  width: 100%;
  text-align: center;
}

.modal--heading {
  font-family: "RoundsBlack";
  font-weight: 400;
  font-size: 64px;
}

.modal--description {
  font-size: 24px;
  color: #F1F6F9;
  margin-top: -50px;
}

.modal--description.vrp a {
  color: #ACF800;
  font-weight: 700;
}

.modal--description.vdao a {
  color: #9418E1;
  font-weight: 700;
}

.modal--heading.vrp {
  color: #ACF800;
}

.modal--heading.vdao {
  color: #9418E1;
}

.window--action-button {
  width: 30%;
  height: 50px;
  text-align: center;
  padding-top: 20px;
  border-radius: 20px;
  margin-top: 40px;
  font-family: "RoundsBlack";
  font-size: 24px;
  cursor: pointer;
}

.window--action-button.vrp {
   background: #ACF800;
   color: #1E1E1E;
}

.window--action-button.vdao {
  background: #9418E1;
  color: #F1F6F9;
}

.balance--row {
  padding-top: 0px;
  border-bottom: none;
  height: 36px;
}


.dsc--btn {
  position: absolute;
  top: 48px;
  margin-left: 32px;
  padding: 8px;
  cursor: pointer;
}

.value--row {
  padding-top: 10px;
}

.left--stage--heading {
  padding-top: 3px;
}

.left--balance--row {
  margin-top: 12px;
}
/* Mobile version */

@media screen and (max-width : 768px) {

  body {
    background: #000;
  }

  .buy--section input {
    width: calc(100% - 16px);
  }

  .menu--section {
    display: none;
  }

  .mobile--menu--ctnr {
    display: flex;
  }

  .presale--body,
  .star--section,
  .buy--section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .token--selector {
    margin-top: 32px;
  }

  .star--section,
  .buy--section {
    width: 100%;
  }

  .sale--timer--heading {
    text-align: center;
    font-size: 36px;
  }

  .timer--clock {
    margin-top: 8px;
    font-size: 48px;
  }

  .amount--input {
    margin: 3px;
    width: 100%;
  }


  .invest--section {
    width: 100%;
  }

  .mobile--menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    background: #000;
  }

  .mobile--menu.menu--section {
    display: flex;
  }

  .mobile--sub {
    position: fixed;
    bottom: 40px;
    margin-left: -90px;
  }

  .buy--column--heading, .stage--heading {
    font-size: 16px;
    padding: 16px 0;
  }

  .stage--heading {
    height: 30.5px;
  }

  .modal--container {
    width: 90%;
  }

  .modal--heading {
    font-size: 24px;
  }

  .modal--description {
    margin-top: 0;
  }

  .dsc--btn {
    margin-left: 30px;
  }

  .value--row {
    padding-top: 15px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1919px) {
  .main--submenu {
    margin-left: -54px;
  }

  .buy--column--heading, .stage--heading {
    font-size: 20px;
 }
}

@media screen and (max-width : 556px) {
   .logo--section img {
     width: 120px;
   }

   .row--1 {
     height: 45px;
   }

   .row--2 {
      height: 79.5px;
   }

   .balance--row {
     padding: 4px 0;
     height: 39px;
   }

   .star--image {
      margin-top: -32px;
      z-index: -1;
      width: 90%;
   }

   .sale--timer {
     margin-top: -28px;
   }

   .value--subsection {
     margin-top: -140px;
   }

   .value--subsection.confirm--button {
    margin-top: 0px;
  }


   .connectWallet--btn,
   .wallet--connected {
      width: 120px;
      height: 20px;
      padding-top: 4px;
      font-size: 12px;
   }

   .mobile--menu--ctnr {
    width: 100%;
    justify-content: center;
   }

   .menu--item {
     font-size: 16px;
     padding: 0 10px;
   }

   .menu--section .menu--item  {
      padding: 0 10px;
   }

   .main--submenu {
     width: 100px;
   }

   .mobile--sub {
     margin-left: -20px;
     bottom: 40px;
   }

   .final--button {
     width: 70%;
   }

   .buy--section {
     width: 90%;
   }

   .modal--window {
     width: 60%;
     top: 15%;
   }

   .sale--timer--heading {
     font-size: 15px;
  }

  .timer--clock {
    margin-top: 0px;
  }

  .invest--section {
    margin-top: 30px;
  }

  .buy--section input {
    width: calc(100% - 32px);
  }

  .stage--stats p,
  .stage--stats a {
    color: #F1F6F9 !important;
    text-decoration: none !important;
  }

  .dsc--btn {
    top: 42px;
    margin-left: 21px;
  }

}

@media screen and (max-width: 361px) {
  .stage--stats p {
    font-size: 9px;
  }
}

/* HD scale */

@media screen and (min-width: 1921px) {
  .presale--body {
     min-width: 1900px;
  }

  .star--section img,
  .star--section video {
    width: 100%;
    max-width: 850px;
  }

  .value--subsection {
    max-width: 800px;
  }
  .invest--section {
    max-width: 810px;
  }

  .number--key {
    width: calc(32% - 4px);
    padding-top: 20px;
    height: 50px;
    font-size: 24px;
  }

  .buy--column--heading,
  .stage--heading {
    font-size: 36px;
  }

  .buy--section input {
    font-size: 28px;
    height: 60px;
  }

  .value--text {
    font-size: 28px;
  }

  .stage--stats {
    font-size: 20px;
  }

  .backspace--key {
    width: calc(66% - 4px);
  }

  .invest--section.opened {
    max-width: 810px;
  }

  .row--1 {
    height: 60px
  }

  .row--2 {
    height: 137.5px;
  }

  .stage--heading {
    padding: 16px 0;
    height: 52px;
  }

  .left--balance--row {
    padding: 5px 0;
  }

  .value--row {
    padding-top: 15px;
  }

  .main--submenu {
    margin-left: -55px;
  }
}

.referral--box {
  width: 100%;
  min-height: 128px;
  border-radius: 20px;
  background: linear-gradient(90deg, #5C258D 0%, #4389A2 100%);
  padding: 32px;
}

/* .ref--content {
   padding-top: 20px;
} */

.heading--row {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  min-width: 560px;
}

.ref--add--link {
  display: flex;
}

.YoullGet--Block {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.YouGet--Section {
  width: 100%;
  min-height: 76px;
  background: linear-gradient(90deg, #C33764 0%, #1D2671 100%);
  border-radius: 6px;
}

.FriendsGet--Section {
  width: 26%;
  min-height: 100px;
  background: linear-gradient(90deg, #673AB7 0%, #512DA8 100%);
  border-radius: 6px;
}

.Note--Block {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.GetBlockHead--Text {
  margin: 9px 0 0 9px;
  font-family: Roboto;
  font-size: 16px;
  color: #FFF;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
}


.MoreSymbol--Section {
  width: 30px;
  height: 90px;
  margin-top: -35px;
  display: block
}

.Divider--Block {
  width: 10px;
  height: 20px;
  margin: 10px;
  border-left: 1px solid #FFFFFF;
}

.NoteBlock {
  margin-top: 20px;
  display: flex;
}

.Note--Text {
  line-height: 1.5;
  margin-left: 5px;
  margin-top: 5px;
  font-weight: 100;
  color: #FFF;
}

.Withdraw--Section {
  float: right;
  width: 20%;
  min-width: 440px;
  min-height: 220px;
  background: #E0EAFC;
  background-image: url('../public/images/referral/withdraw_vorpal.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  border-radius: 20px;
  overflow: hidden;
  margin-top: -60px;
}


.CheckBox--Place {
    width: 30px;
    height: 30px;
    background: rgba(53, 47, 68);
    border-radius: 6px;
}

.Percent--Btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  margin-top: 20px;
}

.Value--Section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  background: rgba(53, 47, 68, 0.1);
  border-radius: 6px;
}

.Percent--Section {
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.Percent--Section.percent--right {
  padding-right: 24px;
}

.RefModal--Window {
  position: fixed;
  top: 266px;
  left: calc(50% - 277px);
  width: 554px;
  min-height: 300px;
  background: linear-gradient(180deg, #FFFFFF 0%, #B3B3B3 100%);   ;
  border-radius: 40px;
  z-index: 199;
  display: block;

}


.RefModal--Window.active {
  display: block;
}


.Note--Input {
  width: 100%;
  height: 40px;
  border: none;
  background: #352F4433; 
  border-radius: 6px;
}

.Note--Hint {
  position: absolute;
  display: flex;
  margin-top: -34px;
  margin-left: 4px;
}

.StyledCard--Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #2D2D2D;
  opacity: 0.7;
  z-index: 190;
  display: none;
}

.StyledCard--Overlay.active {
  display: block
}

.WithdrawNumber--Input {
  width: 100%;
  height: 60px;  
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px #D3CEE0, inset 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
}

.WithdrawAll--Btn {
  position: absolute;
  height: 52px;  
  margin-top: 4px;
  margin-left: -132px;
  background: #352F44;
  color: #FFFFFF;
  font-size: 18px;
  border-radius: 6px;
  text-transform: none;
}

.copy--modal {
  position: fixed;
  z-index: 699;
  bottom: 0;
  right: 10%;
  width: 333px;
  height: 76px;
  border-radius: 20px;
  background: linear-gradient(90deg, #E0EAFC 0%, #CFDEF3 100%);
  transition-property:bottom;
  transition-duration: .4s;
  transition-timing-function: linear;
}

.error--modal {
  position: fixed;
  z-index: 699;
  width: 440px;
  height: 90px;
  top: 0;
  right: 10%;
  border-radius: 20px;
  background: linear-gradient(90deg, #e56e9b 0%, #e11b38 100%);
  transition-property:top;
  transition-duration: .4s;
  transition-timing-function: linear;
}

.success--modal {
  position: fixed;
  z-index: 699;
  width: 440px;
  height: 90px;
  bottom: 0;
  right: 10%;
  border-radius: 20px;
  background: linear-gradient(90deg, #9ee185 0%, #20e70e 100%);
  transition-property: bottom;
  transition-duration: .4s;
  transition-timing-function: linear;
}

.copy--modal--inner,
.error--modal--inner,
.success--modal--inner {
  margin: 20px;
  display: flex;
  width: 100%;

}

.error--modal--inner,
.success--modal--inner {
  width: 90%;
}

.copy--mark {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #352F44;
}

.copy--notify--text {
  margin: 10px 6px 0 6px;
  font-weight: 500;
}

.copy--close--icon {
  margin-left: 112px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: conic-gradient(rgba(0,0,0,0.1) 0deg, rgba(0,0,0,0.1) 90deg, #49313b 90deg, #313149 360deg);
  animation: roundingBorder;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  cursor: pointer;
}

.err--bkg {
  width: 52px;
  background: conic-gradient(#650f34 0deg, #6e051e 90deg, #c15770 90deg, #dd4055 360deg);
  animation: roundingBorder_err;
  animation-duration: 6s;
}

.ok--bkg {
  width: 69px;
  background: conic-gradient(#176703 0deg, #46a00b 90deg, #99e473 90deg, #8ad955 360deg);
  animation: roundingBorder_ok;
  animation-duration: 6s;
}

.copy--close--icon--inner {
  width: 31px;
  height: 31px;
  margin-top: 5px;
  margin-left: 0px;
  border-radius: 40px;
  background: #CFDEF3;
  color: #313149;
}

.copy--mark img{
  margin: 9px;
}

.copy--close--icon img {
  margin: 5px;
}

.get--section--inner {
  display: flex;
  padding: 20px;
}

.partner--counter {
   color: #F8D300;
   font-size: 64px;
   font-weight: 500;
}

.partner--counter--heading {
   color: #F1F6F9;
   font-size: 32px;
   font-weight: 500;
   margin-left: 40px;
}

.withdraw--value--block {
   width: 50%;
   margin-top: 0px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.Login--btn--desc {
  width: 100%;
  text-align: center;
  font-size: 18px;
}

@media (max-width: 857px) {
  .Withdraw--Section {
    width: 100%;
    margin-top: 40px;
    min-width: 100px;
  }

  .referral--box {
    width: 88%;
  }

  .partner--counter {
    font-size: 32px;
  }

  .partner--counter--heading {
    font-size: 16px;
  }
}